import React, { useEffect } from "react";
import qs from "qs";
import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import { Button } from "antd";

import { Page } from "@app/components/page";
import { download } from "@app/lib/file";
import BookmarkSidebar from "@app/components/document/bookmarks";
import PdfViewer from "@app/components/pdf-viewer";
import ExcelViewer from "@app/components/excel-viewer";
import format from "@app/lib/format";

import state from "@app/state/store/report/document/details";
import bookmarksStore from "@app/state/store/report/document/bookmarks";
import EmptyList from "@app/components/list/empty";
import report from "@app/state/store/report";

import "./style/preview.scoped.scss";

import BookmarkTab from "./bookmark-tab";

function parseParams({ area, excel }) {
    let json;
    try {
        if (area) {
            json = JSON.parse(area);
        } else if (excel) {
            json = JSON.parse(excel);
        }

        // eslint-disable-next-line
    } catch (ex) {}

    return json;
}

const Bookmarks = observer(({ className, ...rest }) => {
    const document = state.document;
    const file = document?.file;
    const preview = document?.preview || file;
    const location = useLocation();
    const readonly = report.readOnly;

    const { document: documentId } = useParams();
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const isFullPage = "fp" === location.pathname.split("/")[1];

    const load = documentId === document?._id;
    let goto = load ? parseParams(params) : undefined;
    const pageTitle = document?.name ?? "Bookmarks";

    useEffect(() => {
        if (documentId && document?._id !== documentId) {
            state.load(documentId);
        } else if (!documentId && document?._id) {
            state.reset();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentId]);

    if (document?.deleted) {
        return (
            <div className="removedDocument">The source document was removed from the system</div>
        );
    }

    return (
        <Page className={classNames("bookmarks", className)} {...rest}>
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title breadcrumbs={!isFullPage}>{pageTitle}</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right shrink>
                    {file?._id && (
                        <Button
                            onClick={() => {
                                download(file._id);
                            }}
                        >
                            Download
                        </Button>
                    )}
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body id="document-viewer" className="page-body" padding={false}>
                    <Viewer preview={preview} goto={goto} document={document} />
                </Page.Body>

                <BookmarkSidebar readonly={readonly} isFullPage={isFullPage} />
            </Page.Layout>
        </Page>
    );
});

const Viewer = observer(({ preview, document, goto }) => {
    const { document: documentId } = useParams();

    const { search } = useLocation();

    const params = qs.parse(search, { ignoreQueryPrefix: true });

    const highlights = [];
    const selectedBookmark = bookmarksStore?.bookmarks?.find(
        (bookmark) => bookmark._id === params.bid,
    );

    if (selectedBookmark) {
        highlights.push(selectedBookmark);
    }

    /**
     * Handle the add highlight event
     */
    const addBookmark = (bookmark) => {
        bookmark.document = document._id;
        bookmarksStore.save(bookmark);
    };

    const Image = () => {
        if (!preview.isImage || !preview.file?._id) {
            return null;
        }

        return (
            <div class="image-preview">
                <img src={format.image(preview.file._id)} />
            </div>
        );
    };

    const renderPage = (props) => {
        const bookmarks = bookmarksStore?.bookmarks || [];

        return (
            <>
                {props.canvasLayer.children}
                {bookmarks
                    .filter(
                        (bookmark) =>
                            bookmark?.areas[0]?.pageIndex === props.pageIndex &&
                            bookmark?.document === documentId,
                    )
                    .map((bookmark) => (
                        <BookmarkTab key={bookmark._id} bookmark={bookmark} />
                    ))}
                {props.textLayer.children}
                {props.annotationLayer.children}
            </>
        );
    };

    if (!preview?._id) {
        return (
            <EmptyList title="No Document Selected" show>
                Please pick a bookmark to preview a document.
            </EmptyList>
        );
    } else if (preview.isImage) {
        return <Image />;
    } else if (preview.isExcel) {
        return <ExcelViewer fileId={preview._id} goto={goto} onBookmark={addBookmark} />;
    } else {
        return (
            <PdfViewer
                fileId={preview._id}
                goto={goto}
                onBookmark={addBookmark}
                renderPage={renderPage}
                highlights={highlights}
            />
        );
    }
});

export default Bookmarks;
