import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation, useParams } from "react-router-dom";
import qs from "qs";
import classNames from "classnames";
import { Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Copy } from "@app/components/clipboard";
import format from "@app/lib/format";

import Content from "./chunks/content";
import Tags from "./chunks/tags";

import "./style/card.scoped.scss";
import confirm from "@app/components/confirm";
import { Link } from "react-router-dom";

const HighlightCard = observer(
    ({
        highlight,
        className,
        onEdit,
        onDelete,
        link = undefined,
        title = undefined,
        mdrId = undefined,
        readonly = false,
        ...rest
    }) => {
        const history = useHistory();
        const location = useLocation();
        const { article } = useParams();

        const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

        /**
         * Remove a highlight
         */
        const remove = async () => {
            let result = await confirm("Are you sure you want to remove the highlight?");
            if (result) {
                onDelete();
            }
        };

        const focused = article && searchParams.snippet === highlight._id;

        const onFocus = () => {
            if (article && !focused) {
                const area = highlight.areas[0] ? `area=${JSON.stringify(highlight.areas[0])}` : "";

                const viewFile = highlight.source === "reactPdfViewer" ? "view-file=true&" : "";

                let link =
                    location.pathname +
                    `?${viewFile}highlight=true&snippet=${highlight._id}&${area}`;

                history.push(link);
            }
        };

        return (
            <div
                className={classNames("highlight", highlight.color, className, { focused })}
                onClick={onFocus}
                {...rest}
            >
                <div className="meta">
                    <div className="user">{highlight.author.name}</div>
                    <div className="date">{format.date(highlight.created)}</div>

                    <div className="icons">
                        <Copy text={highlight.content} icon={true} />
                        {onEdit && !readonly && (
                            <Button
                                icon={<EditOutlined />}
                                type="icon"
                                onClick={() => {
                                    onEdit && onEdit();
                                }}
                            />
                        )}
                        {onDelete && !readonly && (
                            <Button icon={<DeleteOutlined />} type="icon" onClick={remove} />
                        )}
                    </div>
                </div>

                <Content content={highlight.content} disableEdit />
                <Tags value={highlight.tags} />
                {title && <ArticleDetails link={link} title={title} mdrId={mdrId} />}
            </div>
        );
    },
);

const ArticleDetails = ({ link, title, mdrId }) => {
    if (!title || !mdrId || !link) {
        return null;
    }
    return (
        <div className="details">
            <div>
                <Link to={link}>{`${format.cid(mdrId)} - ${title}`}</Link>
            </div>
        </div>
    );
};

export default HighlightCard;
