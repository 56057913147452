import tippy from "tippy.js";
import { PluginKey } from "prosemirror-state";
import { ReactRenderer } from "@tiptap/react";
import { default as MentionList } from "./suggestion-list.jsx";
import { getMarks } from "../utils/content";

export default {
    char: "#",
    pluginKey: new PluginKey("cross-reference"),
    allowedPrefixes: null,
    allowSpaces: true,

    items: ({ editor, query }) => {
        return editor.storage["cross-reference"].store.crossReferences
            .filter((reference) => reference.label.toLowerCase().includes(query.toLowerCase()))
            .slice(0, 19);
    },

    render: () => {
        let component;
        let popup;

        const onExit = () => {
            if (popup && component) {
                popup.destroy();
                component.destroy();
            }
        };

        return {
            onStart: (props) => {
                component = new ReactRenderer(MentionList, {
                    props: {
                        ...props,
                        onExit,
                    },
                    editor: props.editor,
                });

                if (!props.clientRect) {
                    return;
                }

                popup = tippy(document.querySelector("div.viewer > div.content"), {
                    getReferenceClientRect: props.clientRect,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                    popperOptions: {
                        modifiers: [{ name: "flip", enabled: false }],
                    },
                    zIndex: 2,
                });
            },

            onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup.setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key === "Escape") {
                    popup.hide();

                    return true;
                }

                return component.ref?.onKeyDown(props);
            },

            onExit,
        };
    },

    command: ({ editor, range, props }) => {
        const nodeAfter = editor.view.state.selection.$to.nodeAfter;
        const overrideSpace = nodeAfter?.text?.startsWith(" ");
        const marks = getMarks({ editor });

        if (overrideSpace) {
            range.to += 1;
        }

        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: "cross-reference",
                    attrs: props,
                    marks,
                },
            ])
            .run();
    },
};
