import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Page } from "@app/components/page";
import { default as Filter } from "@app/components/filter";
import { default as EmptyList } from "@app/components/list/empty";
import { default as confirm } from "@app/components/confirm";
import { Card, CitationModal, CitationFilter } from "@app/components/citation";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

import state from "@app/state/store/report/citation/list";
import report from "@app/state/store/report";

import "./styles/index.scoped.scss";

const CitationsList = observer((props) => {
    const [citation, setCitation] = useState(null);
    const config = report?.config ?? {};
    const location = useLocation();
    const history = useHistory();
    const readonly = report.readOnly;
    const { id } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    useEffect(() => {
        state.load();
    }, []);

    useEffect(() => {
        if (id && state.list) {
            const foundCitation = state.list.find((citation) => citation._id === id);

            if (foundCitation) {
                setCitation(foundCitation);
            }
        }
    }, [state.list, id]);

    const onEdit = (citation) => {
        setCitation(citation);
    };

    const onRemove = async (citation) => {
        let proceed = await confirm("Are you sure you want to remove this reference?");
        if (proceed) {
            await state.remove(citation._id);
        }
    };

    const onUpload = async (citation, file) => {
        citation.file = file._id;
        state.save({
            _id: citation._id,
            file: citation.file,
        });
    };

    const closeModal = () => {
        setCitation(null);

        if (id) {
            history.push(`${location.pathname}`);
        }
    };

    const onSubmit = async (values) => {
        state.save(citation._id ? { ...citation, ...values } : values);
        closeModal();
    };

    const onCancel = () => closeModal();

    const onFetch = (data) => {
        return state.fetchCitation(data);
    };

    return (
        <Page className="citations">
            <Page.Header>
                <Page.Header.Left shrink>
                    <Page.Title>References</Page.Title>
                </Page.Header.Left>

                <Page.Header.Right>
                    {!readonly && (
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setCitation({})}
                        >
                            Add Reference
                        </Button>
                    )}
                </Page.Header.Right>
            </Page.Header>
            <Filter.Header filter={state.filter} />
            <Page.Layout>
                <Page.Body filter={state.filter}>
                    <EmptyList
                        title="No Results Found"
                        show={state.list.length === 0 && !state.loading}
                    >
                        You can use the <b>Add</b> button to add more
                    </EmptyList>
                    {state.list.map((citation) => (
                        <Card
                            citation={citation}
                            key={citation._id}
                            refStyle={config.reference?.style}
                            onEdit={onEdit}
                            onRemove={onRemove}
                            onUpload={(file) => onUpload(citation, file)}
                            readonly={readonly}
                        />
                    ))}
                </Page.Body>

                <CitationFilter filter={state.filter} />
            </Page.Layout>

            <CitationModal
                open={!!citation}
                citation={citation?._id ? citation : undefined}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onFetch={onFetch}
            />
        </Page>
    );
});

export default CitationsList;
